export const uiCtaIntegrator = () => {
  return {
    root: "relative h-(--cta-integrator-height) container-px",
    cover: "absolute w-full h-full top-0 left-0 z-10",
    coverImg: "w-full h-full object-cover rounded-t-(--cta-integrator-radius)",
    backdrop:
      "absolute w-full h-full bg-zinc-900/75 top-0 left-0 z-0 rounded-t-(--cta-integrator-radius)",
    content:
      "relative z-10 h-full mx-auto flex flex-col justify-center max-w-[900px] pb-(--cta-integrator-bottom-height)",
    title:
      "text-3xl text-center font-bold text-white mx-auto mi:text-5xl lg:text-6xl",
    description: "text-lg text-center pt-12",
    h4: "text-2xl font-semibold text-gray-100",
    p: "pt-4 text-gray-200 mx-auto max-w-[400px]",
    actions: "flex justify-center pt-12",
    bottom:
      "h-(--cta-integrator-bottom-height) rounded-t-(--cta-integrator-radius) bg-(--ui-bg) absolute w-full bottom-0 left-0 z-10",
  };
};
