<script setup lang="ts">
import { IconName } from "~/utils/enum/icon_name";
import { PublicAsset } from "~/utils/enum/public_asset";
import { PageName } from "~/utils/enum/page_name";
import { uiCtaIntegrator } from "~/components/cta/ui";

const ui = computed(() => uiCtaIntegrator());
</script>

<template>
  <div :class="ui.root">
    <div :class="ui.cover">
      <div :class="ui.backdrop" />
      <Image
        :class="ui.coverImg"
        :src="PublicAsset.CtaHandShake"
        alt="Специальные цены"
        loading="lazy"
      />
    </div>
    <div :class="ui.content">
      <h2 :class="ui.title">
        Для вас <span class="text-(--ui-warning)">активированы</span
        ><br />специальные цены<br />интеграторов
      </h2>
      <div :class="ui.description">
        <h4 :class="ui.h4">Возникли вопросы?</h4>
        <p :class="ui.p">Свяжитесь с нами и мы обязательно Вам поможем!</p>
      </div>
      <div :class="ui.actions">
        <UButton
          label="Связаться с нами"
          :trailing-icon="IconName.ChevronRight"
          :to="{ name: PageName.Contact }"
          color="neutral"
          variant="outline"
          size="lg"
          external
          class="py-3 px-8"
        />
      </div>
    </div>
    <div :class="ui.bottom" />
  </div>
</template>

<style scoped></style>
