<script setup lang="ts">
const totalLights = 42;
</script>

<template>
  <ul class="light-rope">
    <li v-for="light in totalLights" :key="light" />
  </ul>
</template>

<style scoped>
.light-rope {
  --thematic-light-rope-color-1: 25, 255, 255;
  --thematic-light-rope-color-2: 30, 221, 157;
  --thematic-light-rope-color-3: 246, 110, 189;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
}

.light-rope li {
  position: relative;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  list-style: none;
  padding: 0;
  width: 12px;
  height: 14px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: rgba(var(--thematic-light-rope-color-2), 1);
  box-shadow: 0px 2.3333333333px 24px 2px
    rgba(var(--thematic-light-rope-color-2), 1);
  animation-name: flash-1;
  animation-duration: 2s;
}

.light-rope li:nth-child(2n + 1) {
  background: rgba(var(--thematic-light-rope-color-1), 1);
  box-shadow: 0px 2.3333333333px 24px 2px
    rgba(var(--thematic-light-rope-color-1), 0.5);
  animation-name: flash-2;
  animation-duration: 0.4s;
}

.light-rope li:nth-child(4n + 2) {
  background: rgba(var(--thematic-light-rope-color-3), 1);
  box-shadow: 0px 2.3333333333px 24px 2px
    rgba(var(--thematic-light-rope-color-3), 1);
  animation-name: flash-3;
  animation-duration: 1.1s;
}

.light-rope li:nth-child(odd) {
  animation-duration: 1.8s;
}

.light-rope li:nth-child(3n + 1) {
  animation-duration: 1.4s;
}

.light-rope li:before {
  content: "";
  position: absolute;
  background: #444;
  width: 10px;
  height: 4.6666666667px;
  border-radius: 3px;
  top: -2.3333333333px;
  left: 1px;
}

.light-rope li:after {
  content: "";
  top: -7px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 9.3333333333px;
  border-bottom: solid #444 2px;
  border-radius: 50%;
}

.light-rope li:last-child:after {
  content: none;
}

.light-rope li:first-child {
  margin-left: -40px;
}

@keyframes flash-1 {
  0%,
  100% {
    background: rgba(var(--thematic-light-rope-color-2), 1);
    box-shadow: 0px 2.3333333333px 24px 2px
      rgba(var(--thematic-light-rope-color-2), 1);
  }
  50% {
    background: rgba(var(--thematic-light-rope-color-2), 0.3);
    box-shadow: 0px 2.3333333333px 24px 2px
      rgba(var(--thematic-light-rope-color-2), 0.2);
  }
}

@keyframes flash-2 {
  0%,
  100% {
    background: rgba(var(--thematic-light-rope-color-1), 1);
    box-shadow: 0px 2.3333333333px 24px 2px
      rgba(var(--thematic-light-rope-color-1), 1);
  }
  50% {
    background: rgba(var(--thematic-light-rope-color-1), 0.3);
    box-shadow: 0px 2.3333333333px 24px 2px
      rgba(var(--thematic-light-rope-color-1), 0.2);
  }
}

@keyframes flash-3 {
  0%,
  100% {
    background: rgba(var(--thematic-light-rope-color-3), 1);
    box-shadow: 0px 2.3333333333px 24px 2px
      rgba(var(--thematic-light-rope-color-3), 1);
  }
  50% {
    background: rgba(var(--thematic-light-rope-color-3), 0.3);
    box-shadow: 0px 2.3333333333px 24px 2px
      rgba(var(--thematic-light-rope-color-3), 0.2);
  }
}
</style>
