<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";
import { IconName } from "~/utils/enum/icon_name";
import { Size } from "~/utils/enum/size";
import { numberWithSpaces } from "~/utils/number";
import { isClient } from "@vueuse/core";

const authStore = useAuthStore();
const cartStore = useCartStore();
const siteSettingsStore = useSiteSettingsStore();

const route = useRoute();

const open = ref(false);

function closeIfRepeat(routeName: string) {
  if (route.name !== routeName) return;

  open.value = false;
}

const links = computed(() => [
  {
    label: "Главная",
    external: route.name !== PageName.Home,
    to: {
      name: PageName.Home,
    },
    icon: IconName.Home,
    onSelect: () => {
      closeIfRepeat(PageName.Home);
    },
  },
  {
    label: "Каталог",
    external: route.name !== PageName.CategoryList,
    to: {
      name: PageName.CategoryList,
    },
    icon: IconName.CategoryList,
    onSelect: () => {
      closeIfRepeat(PageName.CategoryList);
    },
  },
  {
    label: "Контакты",
    external: route.name !== PageName.Contact,
    to: {
      name: PageName.Contact,
    },
    icon: IconName.Phone,
    onSelect: () => {
      closeIfRepeat(PageName.Contact);
    },
  },
  {
    label: "Доставка",
    external: route.name !== PageName.Delivery,
    to: {
      name: PageName.Delivery,
    },
    icon: IconName.Truck,
    onSelect: () => {
      closeIfRepeat(PageName.Delivery);
    },
  },
]);

const getCartColorAndVariant = computed(
  (): { variant?: string; color?: string; class?: string } => {
    if (cartStore.getCartTotalProductListLength > 0) {
      if (route.name === PageName.Cart) {
        return {
          color: "success",
          variant: "soft",
        };
      }

      return {
        class: "green-3d text-white",
      };
    }

    return {
      class: "text-(--ui-text-muted)",
      color: "neutral",
      variant: "ghost",
    };
  },
);

const getIsNeedConfirmEmail = computed((): boolean => {
  if (!isClient) return false;

  return authStore.getIsLoggedIn && authStore.getEmailIsNotConfirmed;
});
</script>

<template>
  <UHeader
    v-model:open="open"
    title="Алвима"
    :menu="{ title: 'Навигация', description: 'Навигация по сайту' }"
    :toggle="{
      class: 'text-(--ui-text-muted)',
    }"
  >
    <template #left>
      <NuxtLink
        :to="{ name: PageName.Home }"
        class="max-w-(--header-logo-max-width)"
        aria-label="Алвима"
        :external="route.name !== PageName.Home"
        @click="closeIfRepeat(PageName.Home)"
      >
        <Image :src="siteSettingsStore.getAlvimaThematicLogo" alt="Алвима" />
      </NuxtLink>
    </template>

    <UNavigationMenu
      :items="links"
      highlight
      color="info"
      :ui="{
        linkLeadingIcon: 'size-0',
      }"
    />

    <template #body>
      <div>
        <UNavigationMenu
          :items="links"
          orientation="vertical"
          :ui="{
            list: 'space-y-1',
            link: 'text-base gap-2',
            linkLeadingIcon: 'w-6 h-6',
          }"
        />
      </div>

      <USeparator class="mt-4" />

      <div class="flex items-center justify-center mt-5 mi:hidden">
        <UButton
          v-if="!authStore.getIsLoggedIn"
          label="Вход / Регистрация"
          :trailing-icon="IconName.ArrowRightEndOnRectangle"
          :to="{ name: PageName.AuthLogin }"
          :size="Size.Xl"
          color="info"
          class="px-6 py-3"
          external
        />
        <div v-else-if="authStore.getIsUserInfoFetched" class="relative">
          <ClientOnly>
            <span v-if="getIsNeedConfirmEmail" class="notification" />
          </ClientOnly>

          <UButton
            label="Личный Кабинет"
            :icon="
              authStore.getIsCustomerGroupAssigned
                ? IconName.Star
                : IconName.User
            "
            :to="{ name: PageName.Account }"
            :color="authStore.getIsCustomerGroupAssigned ? 'warning' : 'info'"
            variant="subtle"
            size="xl"
            external
            @click="closeIfRepeat(PageName.Account)"
          />
        </div>
      </div>
    </template>

    <template #right>
      <div class="flex items-center mi:gap-1 md:gap-2">
        <UButton
          class="gap-0 text-[0px] border border-transparent text-(--ui-text-muted) md:gap-x-2 md:text-xs"
          :icon="IconName.MagnifyingGlass"
          variant="ghost"
          color="neutral"
          :to="{ name: PageName.AllProducts }"
          active-class="g-header-tool-active"
          external
          @click="closeIfRepeat(PageName.AllProducts)"
        />

        <UButton
          class="gap-0 text-[0px] border border-transparent text-(--ui-text-muted) md:gap-x-2 md:text-xs"
          :icon="IconName.Heart"
          variant="ghost"
          color="neutral"
          :to="{ name: PageName.AccountFavorites }"
          active-class="g-header-tool-active"
          external
        />

        <UButton
          :icon="IconName.ShoppingCart"
          v-bind="getCartColorAndVariant"
          :label="
            cartStore.getCartTotalProductListLength > 1000
              ? '999+'
              : numberWithSpaces(
                  String(cartStore.getCartTotalProductListLength),
                )
          "
          :to="{ name: PageName.Cart }"
          active-class="g-header-tool-active"
          external
          @click="closeIfRepeat(PageName.Cart)"
        />

        <div :class="['hidden mi:block mi:mr-2 lg:mr-0 relative']">
          <ClientOnly>
            <span v-if="getIsNeedConfirmEmail" class="notification" />
          </ClientOnly>

          <UButton
            v-if="!authStore.getIsLoggedIn"
            label="Войти"
            :trailing-icon="IconName.ArrowRightEndOnRectangle"
            :to="{ name: PageName.AuthLogin }"
            color="info"
            external
          />
          <UButton
            v-else
            truncate
            label="Кабинет"
            :icon="
              authStore.getIsCustomerGroupAssigned
                ? IconName.Star
                : IconName.User
            "
            class="max-w-36"
            :to="{ name: PageName.Account }"
            :color="authStore.getIsCustomerGroupAssigned ? 'warning' : 'info'"
            variant="ghost"
            external
          />
        </div>
      </div>
    </template>
  </UHeader>
</template>

<style scoped>
.notification {
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--ui-error);
  right: -5px;
  top: -5px;
  border-radius: 100%;
}
</style>
