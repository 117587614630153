<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";
import { PublicAsset } from "~/utils/enum/public_asset";
import { uiFooter } from "~/components/footer/ui-footer";

const route = useRoute();

const links = [
  {
    label: "Информация",
    children: [
      {
        label: "Информация о доставке",
        external: route.name !== PageName.Delivery,
        to: {
          name: PageName.Delivery,
        },
      },
      {
        label: "Политика конфиденциальности",
        external: route.name !== PageName.UserAgreement,
        to: {
          name: PageName.UserAgreement,
        },
      },
      {
        label: "Условия оформления заявки",
        external: route.name !== PageName.ApplicationConditions,
        to: {
          name: PageName.ApplicationConditions,
        },
      },
    ],
  },
  {
    label: "Полезные ссылки",
    children: [
      {
        label: "Главная страница",
        external: route.name !== PageName.Home,
        to: {
          name: PageName.Home,
        },
      },
      {
        label: "Каталог",
        external: route.name !== PageName.CategoryList,
        to: {
          name: PageName.CategoryList,
        },
      },
      {
        label: "Все товары",
        external: route.name !== PageName.AllProducts,
        to: {
          name: PageName.AllProducts,
        },
      },
      {
        label: "Контакты",
        external: route.name !== PageName.Contact,
        to: {
          name: PageName.Contact,
        },
      },
    ],
  },
];

const priceHide: string[] = [
  PageName.Contact,
  PageName.AccountConfirmEmail,
  PageName.AccountContact,
  PageName.AccountPassword,
];

const getPriceShow = computed(() => !priceHide.includes(route.name));

const ui = computed(() => uiFooter());
</script>

<template>
  <UFooter>
    <template #top>
      <template v-if="getPriceShow">
        <FooterDocs />
        <div class="py-16 mi:py-20 md:py-24 lg:py-16" />
      </template>

      <UContainer>
        <UFooterColumns :columns="links" />
      </UContainer>
    </template>

    <template #default>
      <div>
        <p class="text-(--ui-text-muted) text-sm text-center">
          <span>
            Обращаем Ваше внимание на то, что данный веб-сайт носит
            исключительно информационный характер и не является публичной
            офертой.
          </span>
          <br />
          <span class="block pt-3">
            Для получения подробной информации о наличии и стоимости указанных
            товаров и (или) услуг, пожалуйста, обращайтесь к
            <NuxtLink class="g-link" :to="{ name: PageName.Contact }" external
              >менеджерам компании</NuxtLink
            >.
          </span>
          <br />
          <span class="block py-3">
            {{ new Date().getFullYear() }} "Алвима" г.Новосибирск.
          </span>
          <span class="block"> Все права сохранены. </span>
        </p>

        <NuxtLink :to="{ name: PageName.Home }" :class="ui.footerLogo" external>
          <Image :src="PublicAsset.AlvimaLogo" alt="Алвима" loading="lazy" />
        </NuxtLink>
      </div>
    </template>
  </UFooter>
</template>

<style scoped></style>
