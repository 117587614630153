<script setup lang="ts">
import { IconName } from "~/utils/enum/icon_name";

const cards: {
  title: string;
  description: string;
  icon: string;
}[] = [
  {
    title: "Специальные цены для интеграторов",
    description:
      "Мы предоставляем специальные цены и акции для интеграторов. Если вы являетесь интегратором, свяжитесь с нами.",
    icon: IconName.Banknote,
  },
  {
    title: "Бесплатная доставка",
    description:
      "У нас действуют специальные условия доставки при достижении определенной суммы заявки.",
    icon: IconName.Truck,
  },
  {
    title: "Опыт и профессионализм",
    description:
      "Наша компания на рынке уже более 10 лет. Мы знаем свое дело и обладаем большим опытом в своей отрасли.",
    icon: IconName.AcademicCap,
  },
  {
    title: "Индивидуальный подход",
    description:
      "Мы всегда работаем на конкретного клиента, учитывая его пожелания и потребности. Мы нацелены на долгосрочное сотрудничество и смотрим на вещи перспективно.",
    icon: IconName.User,
  },
  {
    title: "Широкий ассортимент",
    description:
      "Мы предлагаем широкий выбор товаров и услуг, благодаря чему Вы сможете найти у нас то, что Вам нужно.",
    icon: IconName.ClipboardDocumentList,
  },
  {
    title: "Поддержка",
    description:
      "Наша служба поддержки всегда готова помочь и решить любые вопросы. Мы ценим своих клиентов и стремимся предоставлять им наилучший сервис.",
    icon: IconName.HandThumbUp,
  },
];
</script>

<template>
  <UPageSection title="Наши преимущества">
    <UPageGrid>
      <UPageCard
        v-for="(card, index) in cards"
        :key="index"
        v-bind="card"
        spotlight
        spotlight-color="info"
        :ui="{
          leadingIcon: 'size-9',
        }"
        variant="subtle"
      />
    </UPageGrid>
  </UPageSection>
</template>

<style scoped></style>
