<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";
import { CookieName } from "~/utils/enum/cookie_name";
import { getExpiry } from "~/utils/date";

function confirm() {
  const cookieRefreshToken = useCookie(CookieName.UserAgreement, {
    path: "/",
    expires: getExpiry(),
  });

  cookieRefreshToken.value = "confirmed";
}

const is = ref({
  showTimeout: false,
});

const getIsShow = computed(() => {
  const userAgreement = useCookie(CookieName.UserAgreement, {
    path: "/",
    expires: getExpiry(),
  });

  return userAgreement.value !== "confirmed" && is.value.showTimeout;
});

onMounted(() => {
  setTimeout(() => {
    is.value.showTimeout = true;
  }, 10);
});
</script>

<template>
  <div
    v-if="getIsShow"
    class="cta-cookie fixed bottom-[var(--cta-cookie-bottom)] right-0 w-full max-w-[500px] z-[10]"
  >
    <div
      class="bg-(--ui-bg-accented)/75 mx-4 p-4 rounded-xl backdrop-blur border border-(--ui-border-accented)"
    >
      <div class="text-sm mb-3 flex flex-col gap-1 select-none">
        <p>
          Наш сайт использует cookie. Продолжая им пользоваться, Вы соглашаетесь
          на обработку персональных данных в соответствии с
          <NuxtLink
            :to="{ name: PageName.UserAgreement }"
            external
            class="g-link"
            >политикой конфиденциальности</NuxtLink
          >.
        </p>
      </div>
      <UButton
        label="Понятно"
        color="neutral"
        variant="outline"
        size="lg"
        @click="confirm"
      />
    </div>
  </div>
</template>

<style scoped>
.cta-cookie {
  transition: 0.3s linear all;

  @media (width >= 500px) {
    --cta-cookie-bottom: 16px;
  }
}
</style>
