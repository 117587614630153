<script setup lang="ts">
const props = defineProps<{
  icon: string;
}>();
</script>

<template>
  <UButton
    :icon="props.icon"
    color="neutral"
    variant="ghost"
    class="text-(--ui-text-muted) hover:text-(--ui-text-toned) hover:bg-transparent"
  />
</template>

<style scoped></style>
