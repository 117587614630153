import type { RouteLocationRaw } from "#vue-router";
import { PageName } from "~/utils/enum/page_name";

type Slide = {
  name: string;
  title: string;
  to?: RouteLocationRaw;
};

export const topBarSlides: Slide[] = [
  {
    name: "special-integrator",
    title: "Особые цены для интеграторов",
  },
  {
    name: "free-shipping",
    title: "Возможна бесплатная доставка",
    to: {
      name: PageName.Delivery,
    },
  },
  {
    name: "contact",
    title: "Возникли вопросы?",
    to: {
      name: PageName.Contact,
    },
  },
];
