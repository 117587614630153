<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";

const route = useRoute();
const authStore = useAuthStore();
const siteSettingsStore = useSiteSettingsStore();

const ctaIntegratorHide: string[] = [
  PageName.Contact,
  PageName.Delivery,
  PageName.UserAgreement,
  PageName.ApplicationConditions,
  PageName.Search,
  PageName.Account,
  PageName.AccountConfirmEmail,
  PageName.AccountOrderList,
  PageName.AccountOrderId,
  PageName.AccountContact,
  PageName.AccountPassword,
  PageName.AccountFavorites,
  PageName.CategoryList,
];

const getCtaIntegratorShow = computed(
  () => !ctaIntegratorHide.includes(String(route.name)),
);

const aboutUsFeaturesHide: string[] = [
  PageName.Contact,
  PageName.Delivery,
  PageName.UserAgreement,
  PageName.ApplicationConditions,
  PageName.Search,
  PageName.Account,
  PageName.AccountConfirmEmail,
  PageName.AccountOrderList,
  PageName.AccountOrderId,
  PageName.AccountContact,
  PageName.AccountPassword,
  PageName.AccountFavorites,
  PageName.CategoryList,
];

const getAboutUsFeaturesHide = computed(
  () => !aboutUsFeaturesHide.includes(String(route.name)),
);

const thematicLightRopeHide: string[] = [
  PageName.ProductSlug,
  PageName.CategoryProductSlug,
];

const getThematicLightRopeHide = computed(
  () => !thematicLightRopeHide.includes(String(route.name)),
);
</script>

<template>
  <div class="layout">
    <Topbar />
    <Header />

    <ThematicLightRope
      v-if="
        siteSettingsStore.getIsThematicThemeNewYear && getThematicLightRopeHide
      "
    />

    <slot />

    <div class="pt-16 sm:pt-24 lg:pt-32">
      <CtaAlreadyIntegrator
        v-if="authStore.getIsCustomerGroupAssigned && getCtaIntegratorShow"
      />
      <CtaIntegrator v-else-if="getCtaIntegratorShow" />
    </div>
    <AboutUsFeatures v-if="getAboutUsFeaturesHide" />
    <div class="py-14 mi:py-16 sm:py-20" />

    <Footer />

    <CtaMobileFixedAuth />

    <CtaCookie />
  </div>
</template>

<style scoped></style>
