<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import type { Swiper as SwiperClass } from "swiper/types";
import { topBarSlides } from "./slides";
import { IconName } from "~/utils/enum/icon_name";
import { topBarSwiperModules } from "~/components/topbar/modules";

let $swiper: undefined | SwiperClass = undefined;

function onSwiper(swiper: SwiperClass) {
  $swiper = swiper;
}

function slideNext() {
  $swiper?.slideNext();
}

function slidePrev() {
  $swiper?.slidePrev();
}
</script>

<template>
  <div class="bg-(--ui-bg-elevated) border-b border-(--ui-border)">
    <UContainer>
      <div class="flex items-center max-w-96 mx-auto">
        <TopbarButton :icon="IconName.ArrowSmallLeft" @click="slidePrev" />
        <Swiper
          :modules="topBarSwiperModules"
          :slides-per-view="1"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: true,
          }"
          loop
          @swiper="onSwiper"
        >
          <SwiperSlide v-for="slide in topBarSlides" :key="slide.name">
            <div
              class="flex items-center justify-center select-none text-sm text-(--ui-text-muted)"
            >
              <NuxtLink
                v-if="slide?.to"
                :to="slide.to"
                class="underline transition flex items-center hover:text-(--ui-info)"
                external
              >
                {{ slide.title }}
              </NuxtLink>
              <span v-else>
                {{ slide.title }}
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
        <TopbarButton :icon="IconName.ArrowSmallRight" @click="slideNext" />
      </div>
    </UContainer>
  </div>
</template>

<style scoped></style>
