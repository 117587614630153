<script setup lang="ts">
import { Size } from "~/utils/enum/size.js";
import { PageName } from "~/utils/enum/page_name";
import { IconName } from "~/utils/enum/icon_name";

const authStore = useAuthStore();

const is = ref({
  showTimeout: false,
});

onMounted(() => {
  setTimeout(() => {
    if (!authStore.getIsLoggedIn) {
      document.documentElement.style.cssText = "--cta-cookie-bottom: 100px";
    }

    is.value.showTimeout = true;
  }, 5000);
});
</script>

<template>
  <ClientOnly>
    <div
      v-if="!authStore.getIsLoggedIn"
      class="transition translate-y-[100px] fixed bottom-0 py-3 left-0 z-[50] w-full flex items-center justify-center mi:hidden bg-(--ui-bg-accented)/75 backdrop-blur rounded-t-xl border-t border-(--ui-border-accented)"
      :class="[{ 'translate-y-[0px]!': is.showTimeout }]"
    >
      <UButton
        label="Вход / Регистрация"
        :size="Size.Xl"
        color="neutral"
        variant="outline"
        :to="{ name: PageName.AuthLogin }"
        :trailing-icon="IconName.ArrowRightEndOnRectangle"
        class="px-12 py-3"
      />
    </div>
  </ClientOnly>
</template>

<style scoped>
.transition {
  transition: 0.3s linear all;
}
</style>
