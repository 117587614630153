<script setup lang="ts">
// TODO переместить компонент в cta

import { IconName } from "~/utils/enum/icon_name";
import { PageName } from "~/utils/enum/page_name";
import { uiFooterDocs } from "~/components/footer/ui-footer-docs";

const ui = computed(() => uiFooterDocs());
</script>

<template>
  <div :class="ui.root">
    <UContainer>
      <div :class="ui.container">
        <div :class="ui.content">
          <div :class="ui.title">Нужен прайс<br />или реквизиты?</div>
          <UButton
            class="justify-center w-full py-3 mt-6 sm:mt-12 sm:py-4 sm:text-lg lg:mt-0"
            label="Скачайте здесь"
            :to="{
              name: PageName.Contact,
              hash: '#contact-file-list',
            }"
            size="lg"
            :trailing-icon="IconName.ChevronRight"
            color="neutral"
            variant="outline"
            external
          />
        </div>
      </div>
    </UContainer>
  </div>
</template>

<style scoped></style>
